import React, { useRef, useState, useEffect, useContext } from 'react'
import { useSearchParams, useNavigate, Link } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import { formatDate, topFunction } from '../Utils/utils'

export default function User() {
    let [ searchParams ] = useSearchParams();
    const id = searchParams.get("id");
    const [state, setState] = useState({id: '2'})
    const [original, setOriginal] = useState(null)
    const [portals, setPortals] = useState(null)
    const [confirm, setConfirm] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState(false)
    const { user, setUser } = useContext(UserContext);
    const navigate = useNavigate();

    // DOM References
    const refUsername = useRef();
    const refFirstName = useRef();
    const refLastName = useRef();
    const refRole = useRef();
    const refStatus = useRef();
    const refExpDate = useRef();
    const refReturnTop = useRef();
    const refSubmit = useRef();
    const refPortal = useRef();
    const refPortalError = useRef();
    const refPassword = useRef();
    const refConfirmPassword = useRef();

    // DOM Error References
    const refUsernameError = useRef();
    const refPasswordError = useRef();
    const refFirstNameError = useRef();
    const refLastNameError = useRef();
    const refRoleError = useRef();
    const refStatusError = useRef();
    const refExpDateError = useRef();

    // Heading Modal References
    const overlay = useRef();
    const popup = useRef();
    const modalHeading = useRef();
    const modalContent = useRef();
    const modalClose = useRef();
    
    // Confirm Modal References
    const popupConfirm = useRef();
    const popupConfirmHeading = useRef();
    const popupConfirmContent = useRef();
    const popupDeleteConfirm = useRef();

    // Handler for all input and textarea elements
    const handleChange = e => {
        const { name, value } = e.target;
        if(name === 'customer_number'){
            let split = state.customer_number.split(',');
            let toAdd = true;
            for(let a=0;a<split.length;a++){
                if(split[a] === value){
                    toAdd = false;
                }
            }

            if(toAdd){
                split.push(value)
                let newList = [];
                for(let a=0;a<portals.length;a++){
                    for(let b=0;b<split.length;b++){
                        if(portals[a].customer_number == split[b]){
                            newList.push(split[b])
                        }
                    }
                }

                setState(prevState => ({
                    ...prevState,
                    [name]: newList.join(',')
                }));    
            }
        }else{
            setState(prevState => ({
                ...prevState,
                [name]: value ? value : null
            }));
        }
    };

    async function handleDelete(){
        if(!confirmDelete){
            deleteOverlay();
        }else{
            const requestOptions = {
                method: 'DELETE',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
            };
    
            let response = await fetch('https://picform.lahlouhonline.com/api/users/' + state.id, requestOptions);

            if(response.status === 403){
                infoModal("You've been logged out", "You must be logged in to complete this action. Please refresh the page and log back in.")
            }else{
                let data = await response.json()
                if(data.message){
                    setTimeout(() => {
                        infoModal('Deleted', 'User has been successfully deleted', true);
                    }, 150);
                    setTimeout(() => {
                        navigate(-1);
                    }, 2100);
                }else{
                    setTimeout(() => {
                        infoModal('Error', 'User was unable to be deleted', true);
                    }, 150);
                }
            }
        }
    }

    // Delete Modal
    async function deleteOverlay(){
        let scrollbarWidth = window.innerWidth - document.body.clientWidth;
        document.body.style.overflow = "hidden";
        document.body.style.marginRight = scrollbarWidth + 'px';
        overlay.current.className = 'show';
        popupDeleteConfirm.current.className = 'show';
    }

    async function handleSubmit(){
        let shouldSubmit = true;

        refFirstName.current.classList.remove('error-outline');
        refFirstNameError.current.style.display='none';
        refLastName.current.classList.remove('error-outline');
        refLastNameError.current.style.display='none';
        refUsername.current.classList.remove('error-outline');
        refUsernameError.current.style.display='none';
        refRole.current.classList.remove('error-outline');
        refRoleError.current.style.display='none';
        refStatus.current.classList.remove('error-outline');
        refStatusError.current.style.display='none';
        refExpDate.current.classList.remove('error-outline');
        refExpDateError.current.style.display='none';
        refPortal.current.classList.remove('error-outline');
        refPortalError.current.style.display='none';

        refPassword.current.classList.remove('error-outline');
        refConfirmPassword.current.classList.remove('error-outline');
        refFirstNameError.current.style.display='none';

        if(state.fname === ''){
            refFirstNameError.current.innerHTML = 'Please enter a first name.';
            refFirstName.current.classList.add('error-outline');
            refFirstNameError.current.style.display='block';
            shouldSubmit = false;
        }

        if(state.lname === ''){
            refLastNameError.current.innerHTML = 'Please enter a last name.';
            refLastName.current.classList.add('error-outline');
            refLastNameError.current.style.display='block';
            shouldSubmit = false;
        }

        if(state.username === ''){
            refUsernameError.current.innerHTML = 'Please enter an email address.';
            refUsername.current.classList.add('error-outline');
            refUsernameError.current.style.display='block';
            shouldSubmit = false;
        }

        // Password Reset
        // Verify passwords match
        if(refPassword.current.value !== '' || refConfirmPassword.current.value !== ''){
            if(refPassword.current.value !== refConfirmPassword.current.value){
                refPasswordError.current.innerHTML = 'Passwords do not match.';
                refPassword.current.classList.add('error-outline');
                refConfirmPassword.current.classList.add('error-outline');
                refPasswordError.current.style.display='block';
                shouldSubmit = false;
            }
        }

        if(state.username !== ''){
            let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            if(!state.username.match(regex)){
                refUsernameError.current.innerHTML = 'Please enter a valid email address.';
                refUsername.current.classList.add('error-outline');
                refUsernameError.current.style.display='block';  
                shouldSubmit = false;
            } 
        }

        if(state.role.indexOf('Select') !== -1 ){
            refRoleError.current.innerHTML = 'Please select a role.';
            refRole.current.classList.add('error-outline');
            refRoleError.current.style.display='block';
            shouldSubmit = false;
        }

        if(state.status.indexOf('Select') !== -1){
            refStatusError.current.innerHTML = 'Please select a status.';
            refStatus.current.classList.add('error-outline');
            refStatusError.current.style.display='block';
            shouldSubmit = false;
        }

        // Portal Validation - None selected
        if(state.customer_number.split(',')[0] === ''){
            refPortal.current.classList.add('error-outline');
            refPortalError.current.innerHTML = 'Please select a portal.';
            refPortalError.current.style.display='block';
            shouldSubmit = false;
        }

        // Portal Validation - External should only have one portal
        if(state.role === 'external'){
            if(state.customer_number.split(',').length > 1){
                refPortal.current.classList.add('error-outline');
                refPortalError.current.innerHTML = "Only 1 portal may be assigned to users with 'external' role.";
                refPortalError.current.style.display='block';
                shouldSubmit = false;
            }
        }

      
        // Expiration Date Validation
        if(state.exp_date !== original.exp_date){
            if(new Date(state.exp_date) <= new Date()){
                refExpDateError.current.innerHTML = 'Date must be at least 1 day in the future.';
                refExpDate.current.classList.add('error-outline');
                refExpDateError.current.style.display = 'block';
                shouldSubmit = false;
            }
        }

        let response;
        let data;
        
        if(shouldSubmit){
            // Data is different - show Modal
            if(JSON.stringify(original) !== JSON.stringify(state)){
                // confirm is a state variable
                if(!confirm){
                    confirmOverlay();
                }
            // Data is the same
            }else{
                if(refPassword.current.value !== ''){
                    if(!confirm) setConfirm(true);
                }else{
                    infoModal('No changes detected', 'User data cannot be updated unless changes are made to the form.')
                }
            }
            
            // Data is different - Confirmed and ready to submit
            if(confirm){
                let requestObj = {
                    username: state.username,
                    fname: state.fname,
                    lname: state.lname,
                    role: state.role,
                    customer_number: state.customer_number,
                    status: state.status,
                    customer_number: state.customer_number,
                }

                if(refPassword.current.value !== '') requestObj.password = refPassword.current.value;
                
                if(state.status === 'active'){
                    if(new Date(state.exp_date) <= new Date()){
                        state.exp_date = '';
                    }
                }
                
                if(state.exp_date){
                    requestObj.exp_date = state.exp_date;
                }else{
                    requestObj.exp_date = null;
                }

                if(new Date(state.exp_date) <= new Date()){
                    state.status = 'disabled';
                    requestObj.status = 'disabled'
                }

                let requestOptions = {
                    method: 'PATCH',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(requestObj)
                };
                
                response = await fetch('https://picform.lahlouhonline.com/api/users/' + state.id, requestOptions)
                
                if(response.status === 403){
                    infoModal("You've been logged out", "You must be logged in to complete this action. Please refresh the page and log back in.")
                    //navigate(0)
                }else{
                    data = await response.json()
                    if(data.username){
                        setTimeout(() => {
                            infoModal('Updated', 'User has been successfully updated', true);
                        }, 150);

                        await setOriginal(state)
                        setConfirm(false)

                        // if(state.id !== id){
                        //     navigate('/user?id=' + state.id)
                        // }
                    }else{
                        setTimeout(() => {
                            infoModal('Error', 'User was unable to be updated. Please try again shortly', true);
                        }, 150);
                    }
                }
            }
        }
    }

    function nullToString(obj){
        for(let i=0;i<Object.values(obj).length;i++){
            if(Object.values(obj)[i] === null){
            obj[Object.keys(obj)[i]] = ''
            }
        }
        return obj;
    }

    // Modal for general dialogues
    async function confirmOverlay(){
        let text = '<div class="center mt10">The following items have changed from their original value:</div>';
        
        for(let i=0;i<Object.values(original).length;i++){
          if(Object.values(original)[i] !== Object.values(state)[i]){
            let item;

            text += `<div class="changed-wrapper mt5">`
            text += `<div><strong>${Object.keys(original)[i]}:</strong></div>`
            // Build left side of = - Starting with Empty
            if(!Object.values(original)[i]){
              text += `<div>empty `
            // Build left side of =
            }else{
              if(Object.keys(original)[i] === 'customer_number'){
                let split = Object.values(original)[i].split(',');
                // Single Portal
                if(split.length === 1){
                    item = portals.filter(item => item.customer_number == Object.values(original)[i])
                    text += '<div>' + item[0].name;
                // Multiple Portals
                }else{
                    text += '<div>';
                    for(let a=0;a<split.length;a++){
                        item = portals.filter(item => item.customer_number == split[a])
                        if(a===0){
                            text += item[0].name;
                        }else{
                            text += ', ' + item[0].name;
                        }
                    }
                }
              }else{
                text += `<div>${Object.values(original)[i]}`
              }
            }
            
            // Build right side of =
            if(Object.keys(original)[i] === 'customer_number'){
                let split = Object.values(state)[i].split(',');

                // Single Portal
                if(split.length === 1){
                    item = portals.filter(item => item.customer_number == Object.values(state)[i])
                    text += ' <span>&#129054;</span> ' + item[0].name;
                    text += '</div></div>';
                // Multiple portals
                }else{
                    text += ' <span>&#129054;</span> ';
                    for(let a=0;a<split.length;a++){
                        item = portals.filter(item => item.customer_number == split[a])
                        if(a===0){
                            text += item[0].name;
                        }else{
                            text += ', ' + item[0].name;
                        }
                    }
                    text += '</div></div>';
                }
            }else{
                text += ` <span>&#129054;</span> ${Object.values(state)[i]}</div></div>`;
            }
          }
        }
        
        // Change from the internal object property names to a nicer display version
        text = text
        .replace('username', 'Email')
        .replace('fname', 'First Name')
        .replace('lname', 'Last Name')
        .replace('role', 'Role')
        .replace('company', 'Company')
        .replace('status', 'Status')
        .replace('exp_date', 'Expiration Date')
        .replace('customer_number', 'Portal')
    
        text += '</div>'
        text += '<div class="mt10 center">OK to submit with the above changes?</div>';

        let scrollbarWidth = window.innerWidth - document.body.clientWidth;
        document.body.style.overflow = "hidden";
        document.body.style.marginRight = scrollbarWidth + 'px';
       
        popupConfirmContent.current.innerHTML = text;
        overlay.current.className = 'show';
        popupConfirm.current.className = 'show';
    }

    // Handle the submission of the form when user is OK with the changes (custom confirm dialogue)
    useEffect(() => {
        closePopup();
        if(confirm){
            handleSubmit();
        }
    }, [confirm])

    // Handle the submission of the form when user is OK with the changes (custom confirm dialogue)
    useEffect(() => {
        closePopup();
        if(confirmDelete){
          handleDelete();
        }
    }, [confirmDelete])

    // Pull in MySQL Data - Only once on page load
    useEffect(() => {
        closePopup();
        // Verify JWT Token, and then pull data
        const verifyUser = async () => {
          const requestOptions = {
              method: 'POST',
              credentials: 'include',
          };
  
          let response = await fetch('https://picform.lahlouhonline.com/api/verify', requestOptions);
          let data = await response.json()
          if(data.username){
            const requestOptions = {
              method: 'GET',
              credentials: 'include',
              headers: { 'Content-Type': 'application/json' },
            };
  
            // Get Portals
            response = await fetch('https://picform.lahlouhonline.com/api/clients', requestOptions);
            data = await response.json();
            if(data && data.length > 0){
                await setPortals(data);
                
                // Add options to portal select dropdown
                let option;
                for(let a=0;a<data.length;a++){
                    option = document.createElement("option");
                    option.text = data[a].name;
                    option.key = data[a].customer_number;
                    option.value = data[a].customer_number;
                    refPortal.current.add(option);
                }
            }

            response = await fetch('https://picform.lahlouhonline.com/api/users/' + id, requestOptions)
            data = await response.json()
            
            // String with '[]' to start, longer means there is data
            if(data.fname){
                await setState(nullToString(data))
                await setOriginal(nullToString(data))

                let currentDate = new Date();
                currentDate = currentDate.setDate(currentDate.getDate() + 1)
                currentDate = new Date(currentDate).toISOString().split('T')[0];
                refExpDate.current.setAttribute('min', currentDate);
            }
           
          }else{
            await setUser(null)
          }
        }
  
        verifyUser();
    }, [])

    // Informational Modal
    function infoModal(heading, content, autoclose = false){
        modalHeading.current.innerHTML = heading;
        modalContent.current.innerHTML = content;
        let scrollbarWidth = window.innerWidth - document.body.clientWidth;
        document.body.style.overflow = "hidden";
        document.body.style.marginRight = scrollbarWidth + 'px';
        overlay.current.className = 'show';
        popup.current.className = 'show';
        if(autoclose){
            modalClose.current.style.display = 'none';
            setTimeout(() => {
                document.body.style.overflow = "auto";
                document.body.style.marginRight = '0';
                if(overlay.current) overlay.current.className = '';
                if(popup.current) popup.current.className = '';
            }, 2000)
        }else{
            modalClose.current.style.display = 'block';
        }
    }
    
    // Modal functionality for label headings
    function showModal(e){
        e.preventDefault();
        modalHeading.current.innerHTML = e.target.innerHTML;
        modalContent.current.innerHTML = e.target.title;
        
        let scrollbarWidth = window.innerWidth - document.body.clientWidth;
        document.body.style.overflow = "hidden";
        document.body.style.marginRight = scrollbarWidth + 'px';

        overlay.current.className = 'show';
        popup.current.className = 'show';
    }

    function closePopup(){
        document.body.style.overflow = "auto";
        document.body.style.marginRight = '0';
        if(overlay.current) overlay.current.className = '';
        if(popup.current) popup.current.className = '';
        if(popupConfirm.current) popupConfirm.current.className = '';
        if(popupDeleteConfirm.current) popupDeleteConfirm.current.className = '';
    }
    
    //Close popup by clicking anywhere outside of it
    window.onclick = function(event) {
        if (event.target.id == 'overlay') {
            document.body.style.overflow = "auto";
            document.body.style.marginRight = '0';
            if(overlay.current) overlay.current.className = '';
            if(popup.current) popup.current.className = '';
            if(popupConfirm.current) popupConfirm.current.className = '';
            if(popupDeleteConfirm.current) popupDeleteConfirm.current.className = '';
        }
    }

    // Back to top scroll function
    window.onscroll = function() {scrollFunction()};

    function scrollFunction() {
        if (document.body.scrollTop > 150 || document.documentElement.scrollTop > 150) {
            if(refReturnTop.current){
                refReturnTop.current.style.display = "block";
            }
        } else {
            if(refReturnTop.current){
                refReturnTop.current.style.display = "none";
            }
        }
    }

    async function deleteItem(e){
        let id = e.target.parentNode.getAttribute('data-id')
        let newValue = state.customer_number.split(',').filter(item => item != id).join(',');

        setState(prevState => ({
            ...prevState,
            ['customer_number']: newValue
        }));    
    }

    if(state === null || (user && user.role != 'admin')){
        return (
          <div className="main">
            <div className="main-content">
                
                {state === null
                ?
                <>
                <h1 className="mt10">User Details</h1>
                <div className="mt15 center"><h3 style={{fontWeight:'400'}}>No user to display.</h3></div>
                </>
                :
                <div className="mt15 center"><h3 style={{fontWeight:'400'}}>Unauthorized Access</h3></div>
                }
            </div>
          </div>
        )
    } 
    else{
        return (
        <>
        <div className="main">
            <div className="main-content">
                <h1 className="mt10">User Details</h1>
    
                <div className="mt20 center">
                    Hover or click on a heading to see additional information when available.
                </div>

                <div className="request-header slate-bg mt20">
                    {/* Back to All Users Arrow */}
                    <div className="back-to-users">
                        <Link to="/users" title="Back to all users" className="left-angle" />
                    </div>

                    {/* Request ID */}
                    <div className="request-header-item">
                        <strong>User ID</strong>
                        <div className="mt5">
                        {state.id}
                        </div>
                    </div>

                    {/* Request ID */}
                    <div className="request-header-item">
                        <strong>Last Activity</strong>
                        <div className="mt5">
                        {state.last_active ? formatDate(new Date(state.last_active)) : 'No activity'}
                        </div>
                    </div>
                </div>
                
                <div className="form-bottom">
                    {/* First Name */}
                    <div className="element">
                        <label className="required">First Name</label>
                        <input
                            type="text"
                            ref={refFirstName}
                            name="fname"
                            value={state.fname ? state.fname : ''}
                            onChange={handleChange}
                        />
                        <div ref={refFirstNameError} className="errorMessage"></div>
                    </div>

                    {/* Last Name */}
                    <div className="element">
                        <label className="required">Last Name</label>
                        <input
                            type="text"
                            ref={refLastName}
                            name="lname"
                            value={state.lname ? state.lname : ''}
                            onChange={handleChange}
                        />
                        <div ref={refLastNameError} className="errorMessage"></div>
                    </div>

                    {/* Username */}
                    <div className="element">
                        <label className="required" title="This is the email address used to login" onClick={event => showModal(event)}>Email</label>
                        <input
                            type="text"
                            ref={refUsername}
                            name="username"
                            value={state.username ? state.username : ''}
                            onChange={handleChange}
                        />
                        <div ref={refUsernameError} className="errorMessage"></div>
                    </div>

                    {/* Password */}
                    <div className="element">
                        <label className="required" title="Enter a new password" onClick={event => showModal(event)}>Password</label>
                        <input
                            type="password"
                            ref={refPassword}
                            name="password"
                            autoComplete="new-password"
                            placeholder="Enter a new password"
                        />
                    </div>

                    {/* Confirm Password */}
                    <div className="element">
                        <label className="required" title="Enter the same matching password" onClick={event => showModal(event)}>Confirm Password</label>
                        <input
                            type="password"
                            ref={refConfirmPassword}
                            name="confirm-password"
                        />
                        <div ref={refPasswordError} className="errorMessage"></div>
                    </div>

                    {/* Status */}
                    <div className="element">
                        <label className="required">Status</label>
                        <select 
                            name="status"
                            ref={refStatus}
                            value={state.status} 
                            onChange={handleChange}
                        >
                            <option>Select an option...</option>
                            <option value="active">Active</option>
                            <option value="disabled">Disabled</option>
                        </select>
                        <div ref={refStatusError} className="errorMessage"></div>
                    </div>

                    {/* Role */}
                    <div className="element">
                        <label className="required">Role</label>
                        <select 
                            name="role"
                            ref={refRole}
                            value={state.role} 
                            onChange={handleChange}
                        >
                            <option>Select an option...</option>
                            <option value="admin">Admin</option>
                            <option value="sales">Sales</option>
                            <option value="external">External</option>
                        </select>
                        <div ref={refRoleError} className="errorMessage"></div>
                    </div>

                    {/* Portal Selection */}
                    <div className="element">
                        <label 
                            className={state.role === 'sales' || state.role === 'external' ? "required" : "hide"}
                        >Portals</label>
                        
                        {state.customer_number && portals && state.customer_number.split(',').map(o => (
                        <div 
                          key={o}
                          data-id={o}
                          className={state.role === 'sales' || state.role === 'external' ? "portal-list-item" : "hide"}
                          
                        >
                            {portals.filter(portal => portal.customer_number == o)[0].name}
                            <span 
                                className="delete-list-item"
                                title="Remove Portal"
                                onClick={deleteItem}
                            >x</span>
                        </div>
                        ))}

                        <select 
                            name="customer_number"
                            ref={refPortal}
                            value={state.customer_number}
                            onChange={handleChange}
                            className={state.role === 'sales' || state.role === 'external' ? "" : "hide"}
                        >
                            <option>Select a portal...</option>
                        </select>

                        <div ref={refPortalError} className="errorMessage">
                            Please select a portal.
                        </div>
                    </div>

                    {/* Expiration Date */}
                    <div className="element">
                        <label title="This is the date in which the user account will expire." onClick={event => showModal(event)}>Expiration Date</label>
                        <input
                            type="date"
                            name="exp_date"
                            ref={refExpDate}
                            value={state.exp_date}
                            onChange={handleChange}
                            onKeyDown={(e) => e.preventDefault()}
                        />
                        <div ref={refExpDateError} className="errorMessage"></div>
                    </div>
                </div>

                <div ref={refSubmit} className="mt20 center">
                  <button className="primary-button" onClick={handleSubmit} title="Update User">Update</button>
                  <button className="reject-button" onClick={handleDelete} title="Delete User">Delete</button>
                </div>
            </div>
        </div>

        <div ref={refReturnTop} className="return-top" onClick={topFunction}>
            &#x25B2; Return to top
        </div>

        <div id="overlay" ref={overlay}></div>

        <div id="popup" ref={popup}>
            <h2 id="modal-heading" ref={modalHeading} className="center">Heading</h2>
            <span id="popup-close" ref={modalClose} className="close" onClick={closePopup}>&times;</span>
            <div id="modal-content" ref={modalContent} className="content center">
                Text changed through JS
            </div>
        </div>

        {/*  Popup for Updating of User Information Confirmation */}
        <div id="popup-confirm" ref={popupConfirm}>
            <h2 id="modal-heading" ref={popupConfirmHeading} style={{textAlign:'center'}}>Confirm Changes</h2>
            <span className="close" onClick={closePopup}>&times;</span>
            <div id="modal-content" ref={popupConfirmContent} className="content">
                Text changed through JS
            </div>
            <div className="mt10 center">
                <button className="primary-button smaller-button" onClick={(e) => {setConfirm(true)}} title="Yes">Yes</button>
                <button className="secondary-button smaller-button" onClick={() => {closePopup();setConfirm(false)}} title="No">No</button>
            </div>
        </div>
        
        {/* Popup for Delete Confirmation */}
        <div id="popup-delete-confirm" ref={popupDeleteConfirm}>
            <h2 id="modal-heading" style={{textAlign:'center'}}>
                Confirm Delete
            </h2>
            <span className="close" onClick={closePopup}>&times;</span>
            <div id="modal-content" className="content">
                <div className="center mt10">
                    Are you sure you want to delete this user?
                </div>
            </div>
            <div className="mt10 center">
                <button className="primary-button smaller-button" onClick={(e) => {closePopup();setConfirmDelete(true)}} title="Yes">Yes</button>
                <button className="secondary-button smaller-button" onClick={() => {closePopup();setConfirmDelete(false)}} title="No">No</button>
            </div>
        </div>
        </>
        )
    }

}
