import React, { useState, useContext, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import PortalsTable from '../Components/PortalsTable';
import { topFunction } from '../Utils/utils'

export default function Portals() {
    const { user, setUser } = useContext(UserContext);
    const [ clients, setClients ] = useState(null)
    const [ original, setOriginal ] = useState(null)
    const [ search, setSearch ] = useState('')

    // DOM References
    const refReturnTop = useRef();
    const refSearch = useRef();
    const refClearSearch = useRef();

    async function handleChange(e){
        e.preventDefault();
        // Adjust width of search box with CSS transition
        if(e.target.value !== ''){
            refSearch.current.style.width = '250px';
            refClearSearch.current.style.display = 'inline';
        }else{
            refSearch.current.style.width = '225px';
            refClearSearch.current.style.display = 'none';
        }

        // Filter result set in real-time
        if(e.target.value === ''){
            setClients(original);
        }else{
            const results = original.filter(item => {
                if(item.customer_number){
                    if(item.customer_number.toString().indexOf(e.target.value) !== -1) return item;
                }
                if(item.name){
                    if(item.name.toLowerCase().indexOf(e.target.value) !== -1) return item;
                }
                if(item.epic){
                    if(item.epic.toLowerCase().indexOf(e.target.value) !== -1) return item;
                }
                return false;
            })
            
            setClients(results)
        }

        setSearch(e.target.value)
    }

    // Pull in MySQL Data - Only once on page load
    useEffect(() => {
        // Verify JWT Token, and then pull data
        const verifyUser = async () => {
            const requestOptions = {
                method: 'POST',
                credentials: 'include',
            };

            let response = await fetch('https://picform.lahlouhonline.com/api/verify', requestOptions);
            let data = await response.json()
            if(data.username){
                const requestOptions = {
                    method: 'GET',
                    credentials: 'include',
                };
        
                response = await fetch('https://picform.lahlouhonline.com/api/clients', requestOptions)

                data = await response.json()

                if(data){
                    setClients(data)
                    setOriginal(data)
                }
            }else{
                await setUser(null)
            }
        }

        verifyUser();
        
    }, [])

    // Back to top scroll function
    window.onscroll = function() {scrollFunction()};

    function scrollFunction() {
        if (document.body.scrollTop > 150 || document.documentElement.scrollTop > 150) {
            if(refReturnTop.current){
                refReturnTop.current.style.display = "block";
            }
        } else {
            if(refReturnTop.current){
                refReturnTop.current.style.display = "none";
            }
        }
    }

    async function clearSearch(){
        setSearch('');
        refSearch.current.style.width = '225px';
        refClearSearch.current.style.display = 'none';
        setClients(original);
    }
    
    if(user && user.role === 'admin'){ 
        return (
        <>
        <div className="main">
        <div className="main-content-wider">
                <h1 className="mt10">Portal Management</h1>

                {clients === null
                ?
                <div className="mt15 center"><h3 style={{fontWeight:'400'}}>No portals to display.</h3></div>
                :
                <>

                <div className="search-flex-wrapper">
                    <div>&nbsp;</div>
                    <div className="search-wrapper">
                        <input
                            className="requests-search center"
                            name="new-search"
                            ref={refSearch}
                            type="text"
                            placeholder="Search for portals..."
                            value={search}
                            onChange={handleChange}
                        />
                        <span
                        className="clear-search"
                        ref={refClearSearch}
                        onClick={clearSearch}
                        title="Clear Search"
                    >x</span>
                    </div>

                    <div>
                        <Link to="/add-portal">
                            <button className="primary-button" title="Add Portal">
                                +
                            </button>
                        </Link>
                    </div>
                </div>

                </>
                }
                {clients != null && clients.length > 0
                ? <PortalsTable clients={clients} />
                : <div className="mt20 center" style={{fontSize:'18px'}}>No results found for the current search.</div>
            }
            </div>
        </div>

        <div ref={refReturnTop} className="return-top" onClick={topFunction}>
            &#x25B2; Return to top
        </div>
        </>
        )
    }
}
