import { useRef } from 'react'
import { topFunction } from '../Utils/utils'

export default function ReceivingStandards() {
  const refReturnTop = useRef();

  // Back to top scroll function
  window.onscroll = function() {scrollFunction()};

  function scrollFunction() {
      if (document.body.scrollTop > 150 || document.documentElement.scrollTop > 150) {
          if(refReturnTop.current){
              refReturnTop.current.style.display = "block";
          }
      } else {
          if(refReturnTop.current){
              refReturnTop.current.style.display = "none";
          }
      }
  }
  
  return (
    <div className="main">
        <div className="main-content">
            <h1 className="mt10">Receiving Standards</h1>

            <div className="mt25 med-width">
                <h2>Shipping & Contact Information</h2>

                <div className="address-wrapper">
                    <div className="address mt10">
                        <h3 className="yellow">California</h3>
                        <div className="mt5">
                            <b>Address</b><br />
                            1649 Adrian Road<br />
                            Burlingame, CA 94010<br />
                            <p>
                                <b>Phone</b><br />
                                (650) 692-6600 Ext 272
                            </p>

                            <p>
                                <b>Email</b><br />
                                <a href="mailto:receiving@lahlouh.com">receiving@lahlouh.com</a>
                            </p>
                        </div>
                    </div>

                    <div className="address mt10">
                        <h3 className="yellow">Ohio</h3>
                        <div className="mt5">
                            <b>Address</b><br />
                            150 Lawton Ave<br />
                            Monroe, OH 45050
                            <p>
                                <b>Phone</b><br />
                                (650) 692-6600 Ext 711
                            </p>

                            <p>
                                <b>Email</b><br />
                                <a href="mailto:fulfillment.ohio@lahlouh.com">fulfillment.ohio@lahlouh.com</a>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="thin-divider mt15"></div>
    
                <h2 className="mt10">Receiving Hours</h2>

                <div className="address">
                    Monday - Friday<br />
                    8:00AM - 4:30PM
                </div>

                <div className="thin-divider mt15"></div>

                <h2 className="mt10">Scheduled Delivery</h2>

                <div className="address">
                    <ul className="bullet-list mt5">
                        <li>Shipments greater than 10 pallets require a delivery appointment.</li>
                        <li>Shipments outside of normal receiving hours require a delivery appointment.</li>
                    </ul>
                </div>

                <div className="thin-divider mt15"></div>

                <h2 className="mt10">Pallets</h2>

                <div className="address">
                    <ul className="bullet-list mt5">
                        <li>Pallet dimensions must be 48"x40"; with a maximum height of 48"</li>
                        <li>Pallets must be in good condition.</li>
                        <li>Cartons must remain on the footprint of the pallet and be secured to it with stretch-wrap and banding when appropriate.</li>
                        <li>The pallet height and count for an item with multiple pallets must be consistent (apart from the partial).</li>
                        <li>No pallet may contain mixed product without prior approval.</li>
                    </ul>
                </div>

                <div className="thin-divider mt15"></div>

                <h2 className="mt10">Cartons</h2>

                <div className="address">
                    <ul className="bullet-list mt5">
                        <li>Items must be packaged in cartons appropriately to prevent damage.</li>
                        <li>There may be only one SKU per master carton</li>
                        <li>
                            Each carton must have an identical piece count except for the partial carton.
                            <ul className="bullet-list mt5">
                                <li>The partial must be marked as "PARTIAL".</li>
                                <li>There may be only one partial carton for each SKU.</li>
                            </ul>
                        </li>
                        <li>
                            Each carton must be labeled with the following information as appropriate:
                            
                            <div className="shipping-label mt10">
                                CUSTOMER CONTACT NAME:<br />
                                COMPANY NAME:<br />
                                PO NUMBER:<br />
                                JOB NUMBER:<br />
                                ITEM #:<br />
                                DESCRIPTION:<br />
                                QUANTITY:<br />
                                UOM:
                            </div>
                        </li>
                    </ul>
                </div>

                <div className="thin-divider mt15"></div>

                <h2 className="mt10">Individual Item Labeling Requirements</h2>

                <div className="address">
                    <ul className="bullet-list mt5">
                        <li>
                            Individual items need to be labeled if the item has no discernable marking that distinguishes it from any other item. Examples include:
                            <ul className="bullet-list mt10">
                                <li>Items packaged individually in blank boxes.</li>
                                <li>Items shrink-wrapped in such a way that no identifiable markings are visible.</li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div ref={refReturnTop} className="return-top" onClick={topFunction}>
            &#x25B2; Return to top
        </div>
    </div>
  )
}
